import React, { useState } from 'react';
import s from './Questions.scss';
import classNames from 'classnames';
import Arrow from 'assets/svg/carrot.svg';

interface q {
  title: string;
  subtitle: string;
}

interface QuestionsProps {
  questions: q[];
}

export const Questions = ({ questions }: QuestionsProps) => {
  const [openFaq, setOpenFaq] = useState<null | number>(0);
  const onClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const id = +e.currentTarget.dataset.id;
    setOpenFaq((openFaq) => {
      if (openFaq === id) {
        return null;
      } else {
        return id;
      }
    });
  };

  return (
    <>
      {questions.map((faq, index) => {
        return (
          <div
            data-id={index}
            key={index}
            onClick={onClick}
            className={classNames('', s.textWrapper)}
          >
            <div className="flex_start_center_row">
              <h1 className={classNames('garamond_30 white_text')}>{faq.title} </h1>
              <span
                className={classNames(
                  openFaq === index ? s.arrowOpen : undefined,
                  s.arrow,
                  'white_text',
                )}
              >
                <Arrow />
              </span>
            </div>
            <div
              className={classNames(
                'brandon_22',
                'margin_top_16',
                'white_text',
                openFaq === index ? s.isOpen : s.isClosed,
              )}
            >
              {faq.subtitle}
            </div>
          </div>
        );
      })}
    </>
  );
};
