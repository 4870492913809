import React, { useState } from 'react';
import s from './FAQ.scss';
import classNames from 'classnames';
import { reviews } from 'utils/copy/yelp';
import { YelpCard } from 'components/yelp-card/YelpCard';
import { faqPage } from 'utils/copy/home';
import Arrow from 'assets/svg/carrot.svg';

export const FAQ = () => {
  const [openFaq, setOpenFaq] = useState<null | number>(null);
  const onClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const id = +e.currentTarget.dataset.id;
    setOpenFaq((openFaq) => {
      if (openFaq === id) {
        return null;
      } else {
        return id;
      }
    });
  };

  return (
    <>
      <section className={s.section_1}>
        <div className={s.wrapper}>
          <h1 className="garamond_61 margin_bottom_48">Frequently Asked Questions</h1>
          {faqPage.map((faq, index) => {
            return (
              <div data-id={index} onClick={onClick} className={classNames('', s.textWrapper)}>
                <div className="flex_start_center_row">
                  <h1 className={classNames('garamond_30')}>{faq.title} </h1>
                  <span
                    className={classNames(openFaq === index ? s.arrowOpen : undefined, s.arrow)}
                  >
                    <Arrow />
                  </span>
                </div>
                <div
                  className={classNames(
                    'brandon_22',
                    'margin_top_16',
                    openFaq === index ? s.isOpen : s.isClosed,
                  )}
                >
                  {faq.subtitle}
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className={s.section_3}>
        <h1 className="garamond_61 white_text margin_bottom_32 text text_align_center">
          What's everyone saying?
        </h1>
        <div className={s.reviews}>
          {reviews.map((review) => {
            return <YelpCard key={review.id} review={review} />;
          })}
        </div>
      </section>
    </>
  );
};
