import { TextStackHero } from 'components';
import React, { ReactNode } from 'react';
import s from './Contact.scss';
import classNames from 'classnames';
import { ContactForm, SubscribeForm } from 'components';

interface Social {
  icon: ReactNode;
  to: string;
}

interface ContactProps {
  social: Social[];
}

export const Contact = ({ social }: ContactProps) => {
  return (
    <>
      <section className={s.section_1}>
        <div className={s.wrapper}>
          <div style={{ marginRight: '40px', marginTop: '40px' }}>
            <div className={classNames('flex_start_colum', 'stack_32')}>
              <TextStackHero
                heading="Get in touch"
                description="We would love to hear from you! Send us a message and we will get back to you asap."
              />
              <ul className={s.social_list}>
                {social.map((item) => (
                  <li key={item.to} className={s.social_item}>
                    <a href={item.to} target="_blank" rel="noopener noreferrer">
                      {item.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div style={{ marginTop: '16px' }}>
              <a href="tel:8587046525" className="brandon_18">
                Call or Text: (858) 704-6525
              </a>
            </div>
            <div style={{ marginTop: '16px' }}>
              <a
                className="brandon_18"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:info@ruadeseo.com"
              >
                info@ruadeseo.com
              </a>
            </div>
          </div>
          <ContactForm />
        </div>
      </section>
      <section className={s.section_2}>
        <div className={s.wrapper}>
          <SubscribeForm whiteText={true} />
        </div>
      </section>
    </>
  );
};
