import React from 'react';

import s from './BookingStack.scss';
import classNames from 'classnames';

interface TextStackButtonProps {
  heading: string;
  callout: string;
  white?: boolean;
  right?: boolean;
}

export const BookingStack = ({
  heading,
  callout,
  white = false,
  right = false,
}: TextStackButtonProps) => {
  return (
    <div className={s.box}>
      <div className={classNames(s.max_width, right ? 'text_align_right' : 'text_align_left')}>
        <div className={'stack_16'}>
          <h1 className={classNames('brandon_20', white ? 'white_text' : undefined)}>{callout}</h1>
          <h2 className={classNames('garamond_61', white ? 'white_text' : undefined)}>{heading}</h2>
        </div>
      </div>
    </div>
  );
};
