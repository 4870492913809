import React, { ReactNode } from 'react';
import s from './PlanYourOwn.scss';
import classNames from 'classnames';
import { TextStackHero } from 'components/text-stack-hero/TextStackHero';
import { ContactForm } from 'components/contact-form';
import { PhotoOverlap } from 'components/photo-overlap/PhotoOverlap';

interface Social {
  icon: ReactNode;
  to: string;
}

interface PlanYourOwnProps {
  social?: Social[];
}

const mainAsset =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/girls-releive.jpg?width=800';
const secondaryAsset =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/selfie-color.jpg?width=800';

export const PlanYourOwn = () => {
  return (
    <>
      <section className={s.section_1}>
        <div className={s.wrapper}>
          <div style={{ marginRight: '40px' }}>
            <PhotoOverlap mainAsset={mainAsset} secondaryAsset={secondaryAsset} />
          </div>
          <div className={s.textWrapper}>
            <TextStackHero
              maxWidth="550px"
              white={true}
              heading="Interested in customizing your trip?"
              description="Say no more! We at Ruadeseo are committed to pleasing our guests and ensuring everyone has a one of a kind experience with us. We offer complete customization, in order for you to plan your very own experience throughout Valle De Guadalupe, Ensenada and or Rosarito. Your custom experience can be  a weekend trip, a multi-day trip, or a week long trip, you name it!  We will create your entire agenda plus provide your group with chauffeur services throughout the duration of your experience. This option is perfect for bachelor/bachelorette parties, birthday parties, company outings etc."
            />
          </div>
        </div>
      </section>
      {/* <section className={s.section_2}>
        <div className={s.wrapper}>
          <div style={{ marginRight: '40px' }}>
            <div className={classNames('flex_start_colum', 'stack_32')}>
              <TextStackHero
                heading="Contact us about planning your trip!"
                description="Send us a text, email, or give us a call about planning your incredible experience south of the border!"
              />
              <ul className={s.social_list}>
                {social?.map((item) => (
                  <li key={item.to} className={s.social_item}>
                    <a href={item.to} target="_blank" rel="noopener noreferrer">
                      {item.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div style={{ marginTop: '16px' }}>
              <a href="tel:8587046525" className="brandon_18">
                Call or Text: (858) 704-6525
              </a>
            </div>
            <div style={{ marginTop: '16px' }}>
              <a
                className="brandon_18"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:info@ruadeseo.com"
              >
                info@ruadeseo.com
              </a>
            </div>
          </div>
          <ContactForm />
        </div>
      </section> */}
    </>
  );
};
