import React from 'react';
import s from './MultiDay.scss';
import classNames from 'classnames';
import { Photo6x6, SubscribeForm } from 'components';
import {
  monteXanic,
  fincaCheers,
  relieve,
  relievePour,
  mexicoFlag,
  devin,
  hogan,
} from '../../../assets/photo-urls';
import { Text6x6 } from 'components/text-6x6';
import { faqMultiBlocks } from 'utils/copy/book';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { BookingWidget } from 'components/booking-widget';
import { PlanYourOwn } from 'components/page-wrappers';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { MultiDayForm } from 'components/multi-day-form';

interface MultiProps {}

export const MultiDay = ({}: MultiProps) => {
  const trackBookNow = () => {
    trackCustomEvent({
      category: 'MultiDay',
      action: 'Click',
      label: 'Book Now',
    });
    // window?.fbq('track', 'InitiateCheckout');
  };

  return (
    <>
      <section className={s.section_0}>
        <div className={s.wrapper}>
          <StaticQuery
            query={graphql`
              query {
                mobile: file(relativePath: { eq: "horse-hero.jpg" }) {
                  childImageSharp {
                    fluid(quality: 50, maxWidth: 560) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                desktop: file(relativePath: { eq: "horse-hero.jpg" }) {
                  childImageSharp {
                    fluid(quality: 50, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={(data) => {
              const sources = [
                data.mobile.childImageSharp.fluid,
                {
                  ...data.desktop.childImageSharp.fluid,
                  media: `(min-width: 768px)`,
                },
              ];
              return (
                <BackgroundImage
                  Tag="section"
                  className={s.image}
                  durationFadeIn={100}
                  fluid={sources}
                />
              );
            }}
          />{' '}
          <div className={s.textWrapper}>
            <div className="stack_16 flex_center_column">
              <h1 className={s.hero_text}>
                Baja California Trips
                <br /> from San Diego
              </h1>
              <div onClick={trackBookNow}>
                <AnchorLink to="/baja-california-trips/#book" title="Book">
                  <span className="button_white_fill" style={{ display: 'inline-block' }}>
                    Inquire Now
                  </span>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="info">
        <PlanYourOwn />
      </div>
      <section className={s.section_2_5}>
        <div className={s.left_pane}>
          <div className={s.included}>
            <h1 className="garamond_61 margin_bottom_16">All-inclusive Trips</h1>
          </div>
          <video
            className={s.video}
            muted={true}
            loop={true}
            autoPlay={true}
            preload="auto"
            src="https://player.vimeo.com/external/575287854.hd.mp4?s=826153e2ba6a6f7d87d5bff099ee410ddeea39b3&profile_id=175&oauth2_token_id=1514575968"
            poster="https://i.vimeocdn.com/video/1189755780-ba54e162c7871e11dbf93a41c05e4ee0e75d5c977f37c06254846ce833726548-d_1920x1080?r=pad"
            playsInline={true}
          ></video>
        </div>
        <div className={s.right_pane}>
          <div className={s.listContainer}>
            <div className={s.hide_mobile}>
              <h1 className="garamond_61 margin_bottom_16 ">All-inclusive Trips</h1>
            </div>
            <ul className={classNames(s.list, 'margin_bottom_32', '')}>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                Pickup/drop-off in Southern California
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                Transport throughout Baja in our Mercedes Sprinters
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                Coordination of all events and reservations
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                Wine tastings, food, drinks
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>Selected activities</li>
            </ul>
          </div>
          <div className="flex_start_column">
            <h1 className="garamond_48 margin_bottom_16">What activities are available?</h1>
            <div className={s.activityWrapper}>
              <div className={s.infoRight}>
                <ul className={s.list}>
                  <li className={classNames(s.listItem, 'brandon_18')}>Private chef dinners</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>Wine tasting</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>Mezcal & Tequila tasting</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>Fine dining</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>Barrel wine tasting</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>Spa Day</li>
                </ul>
              </div>
              <div className={s.infoLeft}>
                <ul className={s.list}>
                  <li className={classNames(s.listItem, 'brandon_18')}>Zip lining</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>Fishing</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>Sailing</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>Yacht Cruise</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>Horseback riding</li>
                  <li className={classNames(s.listItem, 'brandon_18')}>And more!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={s.section_info} id="book">
        <div className={s.wrapper}>
          <h1 className="garamond_61 margin_bottom_16 white_text">Request a Quote</h1>
          <MultiDayForm white={true} />
        </div>
      </section>
      <section className={s.section_2}>
        <Photo6x6
          boxWidthAndHeight={'350px'}
          photos={[monteXanic, devin, relievePour, fincaCheers, relieve, hogan]}
        />
      </section>
      <section className={s.section_faq}>
        <Text6x6 textInfo={faqMultiBlocks} />
      </section>
      <div className={s.mexicoFlag}>
        <div className={s.image} style={{ backgroundImage: `url(${mexicoFlag})` }} />{' '}
      </div>
      <section className={s.section_4}>
        <div className={s.wrapper}>
          <SubscribeForm whiteText={true} />
        </div>
      </section>
    </>
  );
};
