import React, { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import s from './MultiDayForm.scss';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Loader from '../../assets/svg/dot-loader.svg';
import { CheckMark } from '../check-mark/CheckMark';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { QUOTE_FORM } from 'apollo/queries';
import { useLazyQuery } from '@apollo/client';

export const MultiDayForm = ({ white }: { white?: boolean }) => {
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [sendForm, { loading, error, data }] = useLazyQuery(QUOTE_FORM);

  const publicTourFormik = useFormik({
    initialValues: {
      name: '',
      email: '',
      groupSize: '',
      startDate: undefined,
      endDate: undefined,
      budget: '',
      city: '',
      activities: '',
    },
    validationSchema: Yup.object<any>({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      groupSize: Yup.number().required(),
      startDate: Yup.date().required(),
      endDate: Yup.date().required(),
      budget: Yup.number().required(),
      city: Yup.string().required(),
      activities: Yup.string().required(),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (data) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        publicTourFormik.resetForm();
      }, 3000);
    }
    if (error) {
      setSuccess(false);
      publicTourFormik.setFieldError('email', `hmmm let's try that again`);
      publicTourFormik.resetForm();
    }
  }, [data, error]);

  const formatDate = (date: Date) => {
    return (
      (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear()
    );
  };

  const handleSubmit = useMemo(
    () => async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (!publicTourFormik.isValid) {
        setErrorMessage('Please make sure all fields are filled out correctly');
      } else {
        trackCustomEvent({
          category: 'Marketing',
          action: 'Click',
          label: 'Public Tour',
        });

        const startDate = formatDate(new Date(publicTourFormik.values.startDate!));
        const endDate = formatDate(new Date(publicTourFormik.values.endDate!));

        try {
          sendForm({
            variables: {
              quoteRequestInput: {
                name: publicTourFormik.values.name,
                email: publicTourFormik.values.email,
                groupSize: publicTourFormik.values.groupSize,
                budget: publicTourFormik.values.budget,
                startDate: startDate,
                endDate: endDate,
                city: publicTourFormik.values.city,
                activities: publicTourFormik.values.activities,
              },
            },
          });
        } catch (e) {
          console.log(e);
          publicTourFormik.setFieldError('email', `hmmm let's try that again`);
        }
      }
    },
    [publicTourFormik],
  );

  const getButtonContent = () => {
    if (loading) {
      return <Loader />;
    } else if (success) {
      return (
        <div className="flex_center_row">
          <span className="margin_right_8">Thanks!</span> {<CheckMark />}
        </div>
      );
    } else {
      return 'Send';
    }
  };

  const setStartDate = (date: Date) => {
    publicTourFormik.setFieldValue('startDate', date);
  };

  const setEndDate = (date: Date) => {
    publicTourFormik.setFieldValue('endDate', date);
  };

  const handleFocus = () => {
    if (!publicTourFormik.isValid && errorMessage) {
      setErrorMessage(undefined);
    }
  };

  return (
    <form
      data-netlify="true"
      name="multi-day"
      method="post"
      className={classNames('box', s.fullWidth)}
    >
      <input type="hidden" name="form-name" value="multi-day" />
      <div className={(s.box, 'stack_24')}>
        <div className={s.input_row}>
          <div className={classNames('flex_start_column', 'margin_right_24', s.input)}>
            <label
              className={classNames(white ? 'white_text' : undefined, 'brandon_15', s.label)}
              htmlFor="name"
            >
              Name *
            </label>
            <input
              {...publicTourFormik.getFieldProps('name')}
              type="text"
              id="name-input"
              className={classNames('brandon_15', s.fullWidth)}
              placeholder="Name"
              onFocus={handleFocus}
            />
          </div>
          <div className={classNames('flex_start_column', s.input)}>
            <label
              className={classNames(white ? 'white_text' : undefined, 'brandon_15', s.label)}
              htmlFor="email"
            >
              Email *
            </label>
            <input
              {...publicTourFormik.getFieldProps('email')}
              type="email"
              id="email-input"
              className={classNames('brandon_15', s.fullWidth)}
              placeholder="Email"
              onFocus={handleFocus}
            />
          </div>
        </div>
        <div className={s.input_row}>
          <div className={classNames('flex_start_column', 'margin_right_24', s.input)}>
            <label
              className={classNames(white ? 'white_text' : undefined, 'brandon_15', s.label)}
              htmlFor="groupSize"
            >
              Group Size *
            </label>
            <input
              {...publicTourFormik.getFieldProps('groupSize')}
              type="number"
              pattern="\d*"
              id="group-size-input"
              className={classNames('brandon_15', s.fullWidth)}
              placeholder="Group Size"
              onFocus={handleFocus}
            />
          </div>
          <div className={classNames('flex_start_column', s.input)}>
            <label
              className={classNames(white ? 'white_text' : undefined, 'brandon_15', s.label)}
              htmlFor="budget"
            >
              Max per person budget *
            </label>
            <input
              {...publicTourFormik.getFieldProps('budget')}
              type="number"
              pattern="\d*"
              id="group-size-input"
              className={classNames('brandon_15', s.fullWidth)}
              placeholder="$498"
              onFocus={handleFocus}
            />
          </div>
        </div>
        <div className={s.input_row}>
          <div className={classNames('flex_start_column', s.input, 'margin_right_24')}>
            <label
              className={classNames(white ? 'white_text' : undefined, 'brandon_15', s.label)}
              htmlFor="startDate"
            >
              Start Date *
            </label>
            <DatePicker
              className={classNames(s.fullWidth, 'brandon_15')}
              minDate={new Date()}
              name="startDate"
              calendarClassName={s.calendar}
              placeholderText="Start Date"
              selected={publicTourFormik.values.startDate}
              onChange={setStartDate}
              onFocus={handleFocus}
              id="date-input"
            />
          </div>
          <div className={classNames('flex_start_column', s.input)}>
            <label
              className={classNames(white ? 'white_text' : undefined, 'brandon_15', s.label)}
              htmlFor="endDate"
            >
              End Date *
            </label>
            <DatePicker
              className={classNames(s.fullWidth, 'brandon_15')}
              minDate={publicTourFormik.values.startDate}
              name="endDate"
              calendarClassName={s.calendar}
              placeholderText="End Date"
              selected={publicTourFormik.values.endDate}
              onChange={setEndDate}
              onFocus={handleFocus}
              id="date-input"
            />
          </div>
        </div>
        <div className={s.input_row}>
          <div className={classNames('flex_start_column', 'margin_right_24', s.input)}>
            <label
              className={classNames(white ? 'white_text' : undefined, 'brandon_15', s.label)}
              htmlFor="city"
            >
              Pickup City *
            </label>
            <input
              {...publicTourFormik.getFieldProps('city')}
              type="text"
              id="name-input"
              className={classNames('brandon_15', s.fullWidth)}
              placeholder="City"
              onFocus={handleFocus}
            />
          </div>
          <div className={classNames('flex_start_column', s.input)}>
            <label
              className={classNames(white ? 'white_text' : undefined, 'brandon_15', s.label)}
              htmlFor="activities"
            >
              Must include activities *
            </label>
            <input
              {...publicTourFormik.getFieldProps('activities')}
              type="text"
              id="email-input"
              className={classNames('brandon_15', s.fullWidth)}
              placeholder="Wine tasting, horseback riding, etc."
              onFocus={handleFocus}
            />
          </div>
        </div>
      </div>
      <p className="brandon_15 white_text">{errorMessage}</p>
      <button
        className={classNames(
          white ? 'button_white' : 'button',
          success ? s.success : undefined,
          'margin_top_32',
        )}
        style={{ width: '100%' }}
        disabled={!publicTourFormik.dirty || loading}
        onClick={handleSubmit}
      >
        {getButtonContent()}
      </button>
    </form>
  );
};
