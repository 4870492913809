import React, { useEffect } from 'react';
import s from './WineTrip.scss';
import classNames from 'classnames';
import { Photo6x6, SubscribeForm } from 'components';
import {
  monteXanic,
  picnic,
  picnicView,
  fincaCheers,
  relieve,
  relievePour,
} from '../../../assets/photo-urls';
import { faqBlocks } from 'utils/copy/book';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { graphql, StaticQuery, Link } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';
import { Overlay } from 'components/overlay/Overlay';
import { PromoForm } from 'components/promo-form';
import { Questions } from '../questions';
import { BookingStack } from 'components/booking-stack/BookingStack';

interface WineTripProps {
  children: React.ReactNode;
  overylayOpen: boolean;
  setOverlayOpen: (val: boolean) => void;
  promoOverlayOpen: boolean;
  paymentOverlayOpen: boolean;
  setPromoOverlayOpen: (val: boolean) => void;
  setPaymentOverlayOpen: (val: boolean) => void;
}

export const WineTripShellPublic = ({
  children,
  overylayOpen,
  setOverlayOpen,
  promoOverlayOpen,
  paymentOverlayOpen,
  setPromoOverlayOpen,
  setPaymentOverlayOpen,
}: WineTripProps) => {
  const closePromo = () => {
    setPromoOverlayOpen(false);
  };

  const trackBookNow = () => {
    trackCustomEvent({
      category: 'Wine Trip',
      action: 'Click',
      label: 'Book Now',
    });
  };

  return (
    <>
      <section className={s.section_0}>
        <div className={s.wrapper}>
          <StaticQuery
            query={graphql`
              query {
                mobile: file(relativePath: { eq: "monte-xanic.jpg" }) {
                  childImageSharp {
                    fluid(quality: 50, maxWidth: 560) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                desktop: file(relativePath: { eq: "monte-xanic.jpg" }) {
                  childImageSharp {
                    fluid(quality: 50, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={(data) => {
              const sources = [
                data.mobile.childImageSharp.fluid,
                {
                  ...data.desktop.childImageSharp.fluid,
                  media: `(min-width: 768px)`,
                },
              ];
              return (
                <BackgroundImage
                  Tag="section"
                  className={s.imageDark}
                  durationFadeIn={100}
                  fluid={sources}
                />
              );
            }}
          />

          <div className={s.textWrapper}>
            <div className="stack_16 flex_center_column">
              <h1 className={s.hero_text}>
                Valle de Guadalupe
                <br /> Wine Tours
              </h1>
              <div onClick={trackBookNow}>
                <AnchorLink to="/valle-de-guadalupe-public-tours#book-wine-tour" title="Book">
                  <span className="button_white_fill" style={{ display: 'inline-block' }}>
                    See agenda & pricing
                  </span>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {children}
      <section className={classNames(s.section_1, s.terracotta)} id="book-now">
        <div className={s.wrapper}>
          <div className={s.textWrapper}>
            <BookingStack
              white={true}
              callout="Valle de Guadalupe"
              heading="Public Wine Tour Booking"
            />
            <div className="margin_bottom_16" style={{ maxWidth: '600px' }}>
              <p className={classNames('brandon_14_bold', 'white_text')}>Trip Info</p>
              <p className="brandon_18_bold white_text">$245 per/person</p>
              <ul className="brandon_18 white_text" style={{ listStyleType: 'disc' }}>
                <li className="white_text">
                  A minimum of 6 guests need to sign up within 72 hours of departure to operate. If
                  the minimum is not met, you will be refunded or you can opt for a private trip at
                  a different price.
                </li>
                {/* <li className="white_text">
                  BYOB if you'd like to enjoy drinks in the van between wineries
                </li> */}
                {/* <li className="white_text">Agenda cannot be modified</li> */}
                <li className="white_text">
                  Passport, global entry card, or passport card required
                </li>
                <li className="white_text">
                  You are welcome to add more people after booking at a later date
                </li>
                <li className="white_text">
                  Those with Global Entry can stay in the van during Mexico to US border crossing,
                  otherwise we utalize the pedestrian lane
                </li>
                {/* <li className="white_text">
                  Those with Global Entry can stay in the van during Mexico to US border crossing,
                  otherwise we utalize the pedestrian lane
                </li> */}
              </ul>
            </div>
            <p className="brandon_15  margin_bottom_48 white_text">
              - View our cancelation policy{' '}
              <span
                className="openOverlay white_text"
                onClick={() => {
                  setOverlayOpen(true);
                }}
              >
                here
              </span>
            </p>
            <div className="margin_bottom_32">
              <p className={classNames('white_text', 'garamond_30', 'margin_bottom_16')}>
                Interested in a private tour?
              </p>
              <Link to="/valle-de-guadalupe-tours" title="Book">
                <span className="button_white_fill" style={{ display: 'inline-block' }}>
                  Learn more
                </span>
              </Link>
            </div>
          </div>
          <div>
            <iframe
              width="275px"
              height="750px"
              src="https://book.ruadeseo.com/group?calendarId=c_u9d22da03874hgmkli1jk3144s@group.calendar.google.com&itemId=MRYRJ3KFL6PS5YGWGPKA2AF2"
            />
          </div>
        </div>
      </section>
      <section className={s.section_2}>
        <Photo6x6
          boxWidthAndHeight={'350px'}
          photos={[monteXanic, picnic, picnicView, fincaCheers, relieve, relievePour]}
        />
      </section>
      <section className={classNames(s.section_faq, s.terracotta)}>
        <Questions questions={faqBlocks} />
      </section>
      <section className={s.section_4}>
        <div className={s.wrapper}>
          <SubscribeForm />
        </div>
      </section>
      <Overlay overylayOpen={overylayOpen} setOverlayOpen={setOverlayOpen}>
        <h1 className="garamond_61 margin_bottom_16">Cancelation Policy</h1>
        <p className="brandon_18">
          If you need to cancel for any reason, we offer full refunds within 72 hours of booking
          unless your trip date is within 72 hours. Otherwise, we are able to provide a 50% refund
          for individuals or the group up until the trip date.
        </p>
      </Overlay>
      <Overlay overylayOpen={paymentOverlayOpen} setOverlayOpen={setPaymentOverlayOpen}>
        <h1 className="garamond_48 margin_bottom_16">Individual Group Payments</h1>
        <p className="brandon_18">
          If your group is trying to pay individually, please have one person checkout and select
          "Pay by Invoice". Please make sure all guest names and emails are filled out so we can
          send individual invoices.
        </p>
      </Overlay>
      <Overlay overylayOpen={promoOverlayOpen} setOverlayOpen={setPromoOverlayOpen}>
        <div className="flex_center_column">
          <h1
            className={classNames(
              'garamond_61',
              'margin_bottom_16',
              'text_align_center',
              s.promoTitle,
            )}
          >
            Get $10 off
          </h1>
          <p
            style={{ maxWidth: '350px' }}
            className="brandon_18 text_align_center margin_bottom_32"
          >
            Get $10 off per person on our Valle de Guadalupe Day Tour
          </p>
          <PromoForm onSubmit={closePromo} />
          <button onClick={closePromo} className={classNames('brandon_18', s.noThanks)}>
            No thanks, I'll pay full price
          </button>
        </div>
      </Overlay>
    </>
  );
};
