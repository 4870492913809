import React from 'react';
import s from './WineTrip.scss';
import classNames from 'classnames';
import { Photo6x6, SubscribeForm } from 'components';
import {
  monteXanic,
  picnic,
  picnicView,
  fincaCheers,
  relieve,
  relievePour,
} from '../../../assets/photo-urls';
import { faqBlocks } from 'utils/copy/book';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { graphql, StaticQuery, Link } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';
import { Overlay } from 'components/overlay/Overlay';
import { PromoForm } from 'components/promo-form';
import { Questions } from '../questions';
import { BookingStack } from 'components/booking-stack/BookingStack';

interface WineTripProps {
  children: React.ReactNode;
  overylayOpen: boolean;
  setOverlayOpen: (val: boolean) => void;
  promoOverlayOpen: boolean;
  paymentOverlayOpen: boolean;
  setPromoOverlayOpen: (val: boolean) => void;
  setPaymentOverlayOpen: (val: boolean) => void;
}

export const WineTripShell = ({
  children,
  overylayOpen,
  setOverlayOpen,
  promoOverlayOpen,
  paymentOverlayOpen,
  setPromoOverlayOpen,
  setPaymentOverlayOpen,
}: WineTripProps) => {
  const closePromo = () => {
    setPromoOverlayOpen(false);
  };

  const trackBookNow = () => {
    trackCustomEvent({
      category: 'Wine Trip',
      action: 'Click',
      label: 'Book Now',
    });
  };

  return (
    <>
      <section className={s.section_0}>
        <div className={s.wrapper}>
          <StaticQuery
            query={graphql`
              query {
                mobile: file(relativePath: { eq: "vineyard-mobile.jpg" }) {
                  childImageSharp {
                    fluid(quality: 50, maxWidth: 560) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                desktop: file(relativePath: { eq: "vineyard-low.jpg" }) {
                  childImageSharp {
                    fluid(quality: 50, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={(data) => {
              const sources = [
                data.mobile.childImageSharp.fluid,
                {
                  ...data.desktop.childImageSharp.fluid,
                  media: `(min-width: 768px)`,
                },
              ];
              return (
                <BackgroundImage
                  Tag="section"
                  className={s.image}
                  durationFadeIn={100}
                  fluid={sources}
                />
              );
            }}
          />

          <div className={s.textWrapper}>
            <div className="stack_16 flex_center_column">
              <h1 className={s.hero_text}>
                Valle de Guadalupe
                <br /> Wine Tours
              </h1>
              <div onClick={trackBookNow}>
                <AnchorLink to="/valle-de-guadalupe-tours#info" title="Book">
                  <span className="button_white_fill" style={{ display: 'inline-block' }}>
                    See agenda & pricing
                  </span>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {children}
      <section className={classNames(s.section_1)} id="book-now">
        <div className={s.wrapper}>
          <div className={s.textWrapper}>
            <BookingStack
              white={false}
              callout="Valle de Guadalupe"
              heading="Private Wine Tour Booking"
            />
            <div className="margin_bottom_8" style={{ maxWidth: '600px' }}>
              <p className={classNames('brandon_14_bold', '')}>Trip Info</p>
              <ul className="brandon_18 " style={{ listStyleType: 'disc' }}>
                <li className="">We are happy to work with you on winery requests</li>
                <li className="">Passport, global entry card, or passport card required</li>
                <li className="">
                  You are welcome to add more people after booking at a later date
                </li>
                <li className="">
                  Those with Global Entry can stay in the van during Mexico to US border crossing,
                  otherwise we utalize the pedestrian lane
                </li>
              </ul>
            </div>
            <p className="brandon_15  margin_bottom_32">
              - View our cancelation policy{' '}
              <span
                className="openOverlay"
                onClick={() => {
                  setOverlayOpen(true);
                }}
              >
                here
              </span>
            </p>
            <div className="flex_start_row_stack_mobile">
              <div className="margin_right_64 margin_right_0_mobile">
                <h1 className="brandon_18_bold margin_bottom_8">Pricing</h1>
                <div className="margin_bottom_32 flex_start_row">
                  <div
                    className="flex_start_column margin_right_16"
                    style={{ flexDirection: 'column-reverse' }}
                  >
                    <p className="brandon_18  margin_bottom_2">2-3 Guests</p>
                    <p className="brandon_18  margin_bottom_2">4-6 Guests</p>
                    <p className="brandon_18 margin_bottom_2">7-9 Guests</p>
                    <p className="brandon_18 margin_bottom_2">10-13 Guests</p>
                    <p className="brandon_18 margin_bottom_2">14-18 Guests</p>
                    <p className="brandon_18 margin_bottom_2">19-26 Guests</p>
                    <p className="brandon_18 margin_bottom_2">27-32 Guests</p>
                  </div>
                  <div className="flex_start_column" style={{ flexDirection: 'column-reverse' }}>
                    <p className="brandon_18">
                      <span className="brandon_18_bold ">$635</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold">$365</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$275</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$245</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$275</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$245</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$255</span> per/person
                    </p>
                  </div>
                </div>
              </div>
              <div className={s.infoRight}>
                <h1 className="brandon_18_bold margin_bottom_8">Upgraded Pricing</h1>
                <div className="margin_bottom_32 flex_start_row">
                  <div
                    className="flex_start_column margin_right_16"
                    style={{ flexDirection: 'column-reverse' }}
                  >
                    <p className="brandon_18  margin_bottom_2">2-3 Guests</p>
                    <p className="brandon_18  margin_bottom_2">4-6 Guests</p>
                    <p className="brandon_18 margin_bottom_2">7-9 Guests</p>
                    <p className="brandon_18 margin_bottom_2">10-13 Guests</p>
                    <p className="brandon_18 margin_bottom_2">14-18 Guests</p>
                    <p className="brandon_18 margin_bottom_2">19-26 Guests</p>
                    <p className="brandon_18 margin_bottom_2">27-32 Guests</p>
                  </div>
                  <div className="flex_start_column" style={{ flexDirection: 'column-reverse' }}>
                    <p className="brandon_18">
                      <span className="brandon_18_bold ">$675</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold">$410</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$335</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$295</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$335</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$295</span> per/person
                    </p>
                    <p className="brandon_18 ">
                      <span className="brandon_18_bold ">$305</span> per/person
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <iframe
              width="275px"
              height="750px"
              src="https://book.ruadeseo.com/calendar?calendarId=c_fq3vpj9iodadqvg7joe7ckcilk@group.calendar.google.com&itemId=ZSSCZUED52PWMP3EGQEQ6IGS"
            />
          </div>
        </div>
      </section>
      <section className={s.section_2}>
        <Photo6x6
          boxWidthAndHeight={'350px'}
          photos={[monteXanic, picnic, picnicView, fincaCheers, relieve, relievePour]}
        />
      </section>
      <section className={s.section_faq}>
        <Questions questions={faqBlocks} />
      </section>
      <section className={s.section_4}>
        <div className={s.wrapper}>
          <SubscribeForm />
        </div>
      </section>
      <Overlay overylayOpen={overylayOpen} setOverlayOpen={setOverlayOpen}>
        <h1 className="garamond_61 margin_bottom_16">Cancelation Policy</h1>
        <p className="brandon_18">
          If you need to cancel for any reason, we offer full refunds within 72 hours of booking
          unless your trip date is within 72 hours. Otherwise, we are able to provide a 50% refund
          for individuals or the group up until the trip date.
        </p>
      </Overlay>
      <Overlay overylayOpen={paymentOverlayOpen} setOverlayOpen={setPaymentOverlayOpen}>
        <h1 className="garamond_48 margin_bottom_16">Individual Group Payments</h1>
        <p className="brandon_18">
          If your group is trying to pay individually, please have one person checkout and select
          "Pay by Invoice". Please make sure all guest names and emails are filled out so we can
          send individual invoices.
        </p>
      </Overlay>
      <Overlay overylayOpen={promoOverlayOpen} setOverlayOpen={setPromoOverlayOpen}>
        <div className="flex_center_column">
          <h1
            className={classNames(
              'garamond_61',
              'margin_bottom_16',
              'text_align_center',
              s.promoTitle,
            )}
          >
            Get $10 off
          </h1>
          <p
            style={{ maxWidth: '350px' }}
            className="brandon_18 text_align_center margin_bottom_32"
          >
            Get $10 off per person on our Valle de Guadalupe Day Tour
          </p>
          <PromoForm onSubmit={closePromo} />
          <button onClick={closePromo} className={classNames('brandon_18', s.noThanks)}>
            No thanks, I'll pay full price
          </button>
        </div>
      </Overlay>
    </>
  );
};
