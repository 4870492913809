import React from 'react';
import s from './WineTrip.scss';
import classNames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const WineTrip = () => {
  return (
    <div className="">
      <section className={classNames(s.section_info, 'margin_bottom_64')} id="info">
        <div className={s.wrapper}>
          <div className={classNames(s.listContainer, 'margin_right_64')}>
            <div>
              <h1 className="garamond_61 ">Private Wine Tour</h1>
              <p className="brandon_12_bold margin_bottom_24">Starting at $245 per/person</p>
            </div>
            <p className="brandon_12_bold margin_bottom_4">What's included?</p>
            <ul className={classNames(s.list)}>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                Tacos at a locals' favorite taqueria
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                Wine tastings at <strong>2</strong> different wineries
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                A winery dinner served with wine
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                Tips at wineries & restaurants
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                Private transport by Ruadeseo
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                Pickup/drop-off at the location of your choice
                <br /> within San Diego County
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                A cooler stocked with plenty of water
              </li>
            </ul>
            <p className="brandon_12_bold margin_bottom_4">What's not included?</p>
            <ul>
              <li className={classNames(s.listItem, 'brandon_18')}>Driver gratuity</li>
            </ul>
          </div>
          <div className="flex_start_column" style={{ maxWidth: '385px' }}>
            <div className="margin_bottom_32">
              <p className="garamond_37 margin_bottom_16">Private Wine Tour Agenda</p>
              <p className="brandon_18">
                <span className="brandon_18_bold">9:00am - </span>Depart from San Diego
              </p>
              <p className="brandon_18">
                {/* <span className="brandon_18_bold">11:30am - </span>  */}• Tacos at a locals'
                favorite taqueria
              </p>
              <p className="brandon_18">
                {/* <span className="brandon_18_bold">12:45pm - </span>  */}• Wine tasting at Winery
                #1
              </p>
              <p className="brandon_18">
                {/* <span className="brandon_18_bold">2:00pm - </span>  */}• Wine tasting Winery #2
              </p>
              <p className="brandon_18">
                {/* <span className="brandon_18_bold">3:15pm - </span>  */}• Winery dinner served
                with wine
              </p>
              <p className="brandon_18">
                {/* <span className="brandon_18_bold">5:00pm - </span>  */}• Depart back to San
                Diego
              </p>
              <p className="brandon_18">
                <span className="brandon_18_bold">8:00pm - </span>
                Arrive at border crossing
              </p>
              <p className="brandon_18">
                <span className="brandon_18_bold">9:00pm - </span>
                Arrive in San Diego
              </p>
            </div>
            <div className="flex_center_row margin_bottom_48">
              <AnchorLink to="/valle-de-guadalupe-tours#book-now" title="Book">
                <span className="button book_button" style={{ display: 'inline-block' }}>
                  Book Now
                </span>
              </AnchorLink>
            </div>
          </div>
        </div>
      </section>
      <section className={classNames(s.section_info, s.green)}>
        <div className={s.wrapper}>
          <div className={classNames(s.listContainer, 'margin_right_64')}>
            <div>
              <h1 className="garamond_61 white_text">Upgraded Private Wine Tour</h1>
              <p className="brandon_12_bold margin_bottom_24 white_text">
                Starting at $295 per/person
              </p>
            </div>
            <p className="brandon_12_bold white_text margin_bottom_4">What's included?</p>
            <ul className={classNames(s.list)}>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                The same as above except with restaurant upgrades
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                The taco lunch is replaced with a Winery lunch served with wine
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                The winery dinner is replaced with Dinner at Bruma Wine Garden or equivalent
                (subject to availability)
              </li>
            </ul>
            <p className="brandon_12_bold white_text margin_bottom_4">What's not included?</p>
            <ul className={classNames(s.list)}>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Driver gratuity
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Alcohol at dinner
              </li>
            </ul>
          </div>
          <div className="flex_start_column" style={{ maxWidth: '385px' }}>
            <div className="margin_bottom_32">
              <p className="garamond_37 margin_bottom_16 white_text">
                Upgraded Private Wine Tour Agenda
              </p>
              <p className="brandon_18 white_text">
                <span className="brandon_18_bold white_text">9:00am - </span>
                Depart from San Diego
              </p>
              <p className="brandon_18 white_text">
                {/* <span className="brandon_18_bold">12:45pm - </span>  */}• Winery lunch served
                with wine
              </p>
              <p className="brandon_18 white_text">
                {/* <span className="brandon_18_bold">11:30am - </span>  */}• Wine tasting at Winery
                #1
              </p>
              <p className="brandon_18 white_text">
                {/* <span className="brandon_18_bold">2:00pm - </span>  */}• Wine tasting at Winery
                #2
              </p>
              <p className="brandon_18 white_text">
                {/* <span className="brandon_18_bold">3:15pm - </span>  */}• Dinner at Bruma Wine
                Garden (or equivalent)
              </p>
              <p className="brandon_18 white_text">
                {/* <span className="brandon_18_bold">5:00pm - </span>  */}• Depart back to San
                Diego
              </p>
              <p className="brandon_18 white_text">
                <span className="brandon_18_bold white_text">8:00pm - </span>
                Arrive at border crossing
              </p>
              <p className="brandon_18 white_text">
                <span className="brandon_18_bold white_text">9:00pm - </span>
                Arrive in San Diego
              </p>
            </div>
            <div className="flex_center_row margin_bottom_48">
              <AnchorLink to="/valle-de-guadalupe-tours/#book-classic" title="Book">
                <span className="button book_button_light" style={{ display: 'inline-block' }}>
                  Book Now
                </span>
              </AnchorLink>
            </div>
          </div>
        </div>
      </section>
      <section className={s.section_2_5}>
        <div className={s.left_pane}>
          <video
            className={s.video}
            muted={true}
            loop={true}
            autoPlay={true}
            preload="auto"
            src="https://player.vimeo.com/external/575287648.hd.mp4?s=e4dbd914eddd381caddf9942b25515482428a4eb&profile_id=175&oauth2_token_id=1514575968"
            poster="https://i.vimeocdn.com/video/1189755247-8a35da8d57c6afc9c0632aa0d590bf1b21ca14a7c263fd7431a992650055f6d5-d_1920x1080?r=pad"
            playsInline={true}
          ></video>
        </div>
      </section>
      {/* <section className="padding_64_top">
        <div className={classNames('flex_center_row', s.founderRow)}>
          <div style={{ maxWidth: '400px' }} className={s.meetFounder}>
            <h1 className="garamond_61 margin_bottom_24">Meet our Co-Founder, Ryan</h1>
          </div>
          <div className={s.half}>
            <div className={s.founderVideo}>
              <div
                className={s.videoMobile}
                style={{ padding: '56.25% 0 0 0', position: 'relative' }}
              >
                <iframe
                  src="https://player.vimeo.com/video/699611708?h=34f3fb6f79&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen={true}
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="Ruadeseo FAQ - Valle de Guadalupe Wine Tours"
                ></iframe>
              </div>
              <p className="brandon_15 margin_top_16">
                * some questions and answers only apply to private trips
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};
