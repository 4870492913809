import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { encode } from 'utils/encode';
import s from './PromoForm.scss';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Loader from '../../assets/svg/dot-loader.svg';
import { CheckMark } from '../check-mark/CheckMark';

interface PromoProps {
  onSubmit: () => void;
}
export const PromoForm = ({ onSubmit }: PromoProps) => {
  const [loading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const promoFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object<any>({
      email: Yup.string().email().required(),
    }),
    onSubmit: () => {},
  });

  const handleSubmit = useMemo(
    () => async (e: React.SyntheticEvent) => {
      e.preventDefault();
      setIsLoading(true);
      trackCustomEvent({
        category: 'Marketing',
        action: 'Click',
        label: 'Promo Code',
      });
      try {
        await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'promo',
            email: promoFormik.values.email,
          }),
        });
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(true);
        }, 500);
        promoFormik.resetForm();
        setTimeout(() => {
          onSubmit();
        }, 2500);
      } catch (e) {
        console.log(e);
        promoFormik.setFieldError('email', `hmmm let's try that again`);
        setIsLoading(false);
      }
    },
    [promoFormik],
  );

  const getButtonContent = () => {
    if (loading) {
      return <Loader />;
    } else if (success) {
      return (
        <div className="flex_center_row">
          <span className="margin_right_8">Thanks!</span> {<CheckMark />}
        </div>
      );
    } else {
      return 'Get $10';
    }
  };

  return (
    <form
      data-netlify="true"
      name="promo"
      method="post"
      className={classNames(s.form, s.space_right)}
    >
      <input type="hidden" name="form-name" value="promo" />
      <input
        {...promoFormik.getFieldProps('email')}
        type="email"
        id="email-input"
        className={classNames(s.emailInput, 'brandon_15')}
        placeholder="Email"
      />
      <button
        className={classNames('button', success ? s.success : undefined)}
        style={{ width: '100%' }}
        disabled={!promoFormik.isValid || !promoFormik.dirty || loading}
        onClick={handleSubmit}
      >
        {getButtonContent()}
      </button>
    </form>
  );
};
