import React, { useState } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import s from './WeekendTrip.scss';
import classNames from 'classnames';
import { Photo6x6, SubscribeForm, TextStackButton } from 'components';
import {
  fincaView,
  fincaCheers,
  fincaDance,
  livWalk,
  grapes,
  aubsCheers,
} from '../../../assets/photo-urls';
import { Text6x6 } from 'components/text-6x6';
import { faqWeekendBlocks } from 'utils/copy/book';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { YelpCard } from 'components/yelp-card/YelpCard';
import { reviews } from 'utils/copy/yelp';
import { Link, StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Overlay } from 'components/overlay/Overlay';

interface WeekendTripProps {}

export const WeekendTrip = ({}: WeekendTripProps) => {
  const trackBookNow = () => {
    trackCustomEvent({
      category: 'Weekend',
      action: 'Click',
      label: 'Book Now',
    });
    // window?.fbq('track', 'InitiateCheckout');
  };

  const [overylayOpen, setOverlayOpen] = useState(false);
  const [paymentOverlayOpen, setPaymentOverlayOpen] = useState(false);

  return (
    <>
      <section className={s.section_0}>
        <div className={s.wrapper}>
          <StaticQuery
            query={graphql`
              query {
                mobile: file(relativePath: { eq: "monte-xanic.jpg" }) {
                  childImageSharp {
                    fluid(quality: 50, maxWidth: 560) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                desktop: file(relativePath: { eq: "monte-xanic.jpg" }) {
                  childImageSharp {
                    fluid(quality: 50, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={(data) => {
              const sources = [
                data.mobile.childImageSharp.fluid,
                {
                  ...data.desktop.childImageSharp.fluid,
                  media: `(min-width: 768px)`,
                },
              ];
              return (
                <BackgroundImage
                  Tag="section"
                  className={s.image}
                  durationFadeIn={100}
                  fluid={sources}
                />
              );
            }}
          />{' '}
          <div className={s.textWrapper}>
            <div className="stack_16 flex_center_column">
              <h1 className={s.hero_text}>
                Mexican Wine
                <br /> Tasting Getaway
              </h1>
              <div onClick={trackBookNow}>
                <AnchorLink to="/valle-de-guadalupe-weekend-tour/#book" title="Book">
                  <span className="button_white_fill" style={{ display: 'inline-block' }}>
                    Book Now
                  </span>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={s.section_2_5} id="info">
        <div className={s.left_pane}>
          <div className={s.included}>
            <h1 className="garamond_61 margin_bottom_16 white_text">What's included?</h1>
          </div>
          <video
            className={s.video}
            muted={true}
            loop={true}
            autoPlay={true}
            preload="auto"
            src="https://player.vimeo.com/external/575287648.hd.mp4?s=e4dbd914eddd381caddf9942b25515482428a4eb&profile_id=175&oauth2_token_id=1514575968"
            poster="https://i.vimeocdn.com/video/1189755247-8a35da8d57c6afc9c0632aa0d590bf1b21ca14a7c263fd7431a992650055f6d5-d_1920x1080?r=pad"
            playsInline={true}
          ></video>
        </div>
        <div className={s.right_pane}>
          <div className={s.listContainer}>
            <div className={s.hide_mobile}>
              <h1 className="garamond_61 margin_bottom_16 white_text">What's included?</h1>
            </div>
            <h3 className="garamond_30 white_text">Day One</h3>
            <ul className={classNames(s.list, 'margin_bottom_32', 'white_text')}>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Wine tasting at <strong>2</strong> different wineries
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                A wonderful lunch with wine
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Dinner at one of the Valle’s top establishments
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Drinks at a fun after-hours spot <strong>or</strong> a Late night wine tasting
                experience
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Private transport by Ruadeseo
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Pickup/drop-off at the location of your choice within San Diego County
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Coffee + light breakfast on the ride down
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                A cooler stocked with (adult) beverages & plenty of water
              </li>
            </ul>
            <h3 className="garamond_30 white_text">Day Two</h3>
            <ul className={classNames(s.list, 'margin_bottom_32', 'white_text')}>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Brunch with a beautiful view
              </li>
              <li className={classNames(s.listItem, 'brandon_18', 'white_text')}>
                Transport back to San Diego
              </li>
            </ul>
            <h3 className="garamond_30 white_text margin_bottom_16">How do accomadations work?</h3>
            <h4 className="brandon_18 white_text">
              Accommodations are booked separately by the guest, but we will provide accomadation
              options that meet your group's criteria.
            </h4>
          </div>
        </div>
      </section>
      <section className={s.section_info}>
        <div className={s.wrapper}>
          <div className={s.infoLeft}>
            <h1 className="garamond_61 margin_bottom_16 ">Additional Info</h1>
            <ul className={s.list}>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                All tours are <strong>private</strong> - no one else will be joining your group
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                A Passport, Global Entry, or Passport Card is required
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                We depart around 9:30am on day one, and arrive in the U.S. around 3:00pm the next
                day.
              </li>
              <li className={classNames(s.listItem, 'brandon_18', '')}>
                We take care of all agenda planning reservations. If your group has any winery or
                restaurant preferences, please let us know!
              </li>
            </ul>
          </div>
          <div className={s.infoRight}>
            <h1 className="garamond_61 margin_bottom_8">Pricing</h1>
            <p className="brandon_15">- Accomadations not included in pricing</p>
            <p className="brandon_15  margin_bottom_16">
              - View our cancelation policy{' '}
              <span
                className="openOverlay"
                onClick={() => {
                  setOverlayOpen(true);
                }}
              >
                here
              </span>
            </p>
            <div className="margin_bottom_32 flex_start_row">
              <div
                className="flex_start_column margin_right_16"
                style={{ flexDirection: 'column-reverse' }}
              >
                <p className="brandon_18  margin_bottom_2">4-5 Guests</p>
                <p className="brandon_18  margin_bottom_2">6-8 Guests</p>
                <p className="brandon_18 margin_bottom_2">9-13 Guests</p>
                <p className="brandon_18 margin_bottom_2">14-18 Guests</p>
                <p className="brandon_18 margin_bottom_2">19-26 Guests</p>
              </div>
              <div className="flex_start_column" style={{ flexDirection: 'column-reverse' }}>
                <p className="brandon_18">
                  <span className="brandon_18_bold "> $750</span> per/person
                </p>
                <p className="brandon_18 ">
                  <span className="brandon_18_bold"> $585</span> per/person
                </p>
                <p className="brandon_18 ">
                  <span className="brandon_18_bold "> $498</span> per/person
                </p>
                <p className="brandon_18 ">
                  <span className="brandon_18_bold "> $585</span> per/person
                </p>
                <p className="brandon_18 ">
                  <span className="brandon_18_bold "> $498</span> per/person
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={s.section_2}>
        <Photo6x6
          boxWidthAndHeight={'350px'}
          photos={[livWalk, aubsCheers, fincaView, fincaCheers, fincaDance, grapes]}
        />
      </section>
      {/* <section className={s.section_1} id="book">
        <div className={s.wrapper}>
          <div className={s.textWrapper}>
            <TextStackButton
              white={true}
              analytics={{ eventAction: 'Click', eventCategory: 'Rezdy', eventLabel: 'Learn More' }}
              callout="Mexico is Calling"
              heading="Baja Two Day Getaway"
              description="We are so excited to introduce you to Baja California's breathtaking wine country, Valle de Guadalupe. The Valle is a hidden gem that most San Diegans have never discovered. But believe us, you’re bound to be blown away by Valle De Guadalupe’s beautiful wineries, incredible food and vibrant culture."
              buttonText="View our Yelp"
              openExternal={true}
              buttonLink={'https://www.yelp.com/biz/ruadeseo-san-diego-3'}
            />
          </div>
          <div className={s.widget_wrapper}>
            <h2 className="garamond_30 white_text margin_bottom_16">Two Day Trip</h2>
            <h2 className="brandon_18 white_text">Please select your departure date.</h2>
            <p style={{ maxWidth: '300px' }} className="brandon_15  margin_bottom_16 white_text">
              Is your group trying to booking individually?
              <span
                className="openOverlay white_text"
                onClick={() => {
                  setPaymentOverlayOpen(true);
                }}
              >
                {' '}
                Learn More
              </span>
            </p>
            <BookingWidget type="weekend" />
          </div>
        </div>
      </section> */}
      <section className={s.section_faq}>
        <Text6x6 textInfo={faqWeekendBlocks} />
      </section>
      <section className={s.section_3}>
        <h1 className="garamond_61 white_text margin_bottom_32 text text_align_center">
          What's everyone saying?
        </h1>
        <div className={s.reviews}>
          {reviews.map((review) => {
            return <YelpCard key={review.id} review={review} />;
          })}
        </div>
      </section>
      <section className={s.section_4}>
        <div className={s.wrapper}>
          <SubscribeForm whiteText={false} />
        </div>
      </section>
      <Overlay overylayOpen={overylayOpen} setOverlayOpen={setOverlayOpen}>
        <h1 className="garamond_61 margin_bottom_16">Cancelation Policy</h1>
        <p className="brandon_18">
          If you need to cancel for any reason, we offer full refunds within 72 hours of booking
          unless your trip date is within 72 hours. Otherwise, we are able to provide a 50% refund
          for individuals or the group up until the trip date.
        </p>
      </Overlay>
      <Overlay overylayOpen={paymentOverlayOpen} setOverlayOpen={setPaymentOverlayOpen}>
        <h1 className="garamond_48 margin_bottom_16">Individual Group Payments</h1>
        <p className="brandon_18">
          If your group is trying to pay individually, please have one person checkout and select
          "Pay by Invoice". Please make sure all guest names and emails are filled out so we can
          send individual invoices.
        </p>
      </Overlay>
    </>
  );
};
