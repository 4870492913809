import { TextStackButton, PhotoOverlap, Photo6x6, SubscribeForm } from 'components';
import React from 'react';
import s from './Home.scss';
import {
  secondaryAsset,
  topRight,
  topLeft,
  bottomMiddle,
  monteXanic,
  fincaView,
  livWalk,
  chai,
} from '../../../assets/photo-urls';
import { photoGridCopy } from '../../../utils/copy/home';
import logo from '../../../assets/images/logo-accent.png';
import { reviews } from '../../../utils/copy/yelp';
import { YelpCard } from 'components/yelp-card/YelpCard';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import classNames from 'classnames';

export const Home = () => {
  return (
    <>
      <section className={s.videoWrapper}>
        <div className={s.textBox}>
          <img className={s.home_logo} alt="ruadeseo" src={logo} />
          <h2 className={classNames(s.subtitle, 'margin_bottom_16')}>Adventure Forever</h2>
          <AnchorLink to="/#packages" title="Tour Packages">
            <span className="button_fill_bold" style={{ display: 'inline-block' }}>
              Tour Packages
            </span>
          </AnchorLink>
        </div>
        <video
          muted={true}
          loop={true}
          id="coverVideo"
          autoPlay={true}
          preload="auto"
          src="https://player.vimeo.com/external/574314296.hd.mp4?s=ce103698b929bfaa0bbecc4fa51d85081d3938f7&profile_id=175&oauth2_token_id=1514575968"
          poster="https://i.vimeocdn.com/video/1184274320-3e6ba43fc55804ae6ff25a3476869a38c8ad5c7de4f4191071b79d35aa0787c1-d_1920x1080?r=pad"
          playsInline={true}
        ></video>
      </section>
      <section className={s.section_cards} id="packages">
        <h1 className="garamond_61 white_text margin_bottom_48 text text_align_center">
          Ruadeseo Tour Packages
        </h1>
        <div className={s.cardRow}>
          <div className={s.card}>
            <div className="padding_32">
              <h1 className="garamond_34 margin_bottom_4">Valle de Guadalupe</h1>
              <h2 className="brandon_18_bold">Public Wine Tour</h2>
              <p className="brandon_14">Duration: 12 hours</p>
              <p className="brandon_14">$245 per/person</p>
            </div>
            <div style={{ backgroundImage: `url('${livWalk}')` }} className={s.cardImage}></div>
            <div className="padding_32">
              <Link
                className="button"
                style={{ display: 'block' }}
                to="valle-de-guadalupe-public-tours#book-wine-tour"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className={s.card}>
            <div className="padding_32">
              <h1 className="garamond_34 margin_bottom_4">Valle de Guadalupe</h1>
              <h2 className="brandon_18_bold">Private Wine Tour</h2>
              <p className="brandon_14">Duration: 12 hours</p>
              <p className="brandon_14">Starting at: $245 per/person</p>
            </div>
            <div style={{ backgroundImage: `url('${fincaView}')` }} className={s.cardImage}></div>
            <div className="padding_32">
              <Link
                className="button"
                style={{ display: 'block' }}
                to="valle-de-guadalupe-tours#info"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className={s.card}>
            <div className="padding_32">
              <h1 className="garamond_34 margin_bottom_4">Valle de Guadalupe</h1>
              <h2 className="brandon_18_bold">Multi-day Trips</h2>
              <p className="brandon_14">Duration: 2-4 days</p>
              <p className="brandon_14">Starting at: $498 per/person</p>
            </div>
            <div style={{ backgroundImage: `url('${chai}')` }} className={s.cardImage}></div>
            <div className="padding_32">
              <Link className="button" style={{ display: 'block' }} to="baja-california-trips">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className={s.section_1}>
        <div className={s.wrapper}>
          <div style={{ marginRight: '40px' }}>
            <PhotoOverlap mainAsset={monteXanic} secondaryAsset={secondaryAsset} />
          </div>
          <div className={s.textWrapper}>
            <TextStackButton
              white={false}
              callout=""
              analytics={{
                eventAction: 'Click',
                eventCategory: 'Marketing',
                eventLabel: 'Learn More',
              }}
              heading="Your Mexican Wine Daycation Awaits"
              description="Mexico has a wine country? You betcha it does! It's called Valle de Guadalupe, and it's only 1.5 hours south of the San Diego/Mexico border!"
              buttonText="Learn More"
              buttonLink={'/valle-de-guadalupe-tours'}
            />
          </div>
        </div>
      </section>
      <section className={s.section_2}>
        <h1 className="garamond_61 white_text margin_bottom_32 text text_align_center">
          What's everyone saying?
        </h1>
        <div className={s.reviews}>
          {reviews.map((review) => {
            return <YelpCard key={review.id} review={review} />;
          })}
        </div>
      </section>
      <section className={s.section_3}>
        <Photo6x6
          photoTextInfo={{
            topLeftHeading: photoGridCopy.topLeftHeading,
            topLeftDescription: photoGridCopy.topLeftDescription,
            topRightHeading: photoGridCopy.topRightHeading,
            topRightDescription: photoGridCopy.topRightDescription,
            bottomMiddleHeading: photoGridCopy.bottomMiddleHeading,
            bottomMiddleDescription: photoGridCopy.bottomMiddleDescription,
            topRight,
            topLeft,
            bottomMiddle,
          }}
        />
      </section>
      {/* <section className={s.section_4}>
        <div className={s.wrapper}>
          <div className={s.founderPhoto} style={{ backgroundImage: `url('${founders}')` }}></div>{' '}
          <div style={{ marginTop: '64px' }}>
            <TextStackButton
              right={true}
              white={true}
              callout="Hey, there"
              heading="We're Olivia & Ryan"
              description="We are very passionate about traveling throughout Mexico, and started frequenting Valle de Guadalupe several times per month after we met in November of 2020. Now we  are itching to help anyone and everyone discover the beauty that Baja California has to offer."
              buttonText="About us"
              buttonLink="/about-us"
            />
          </div>
        </div>
      </section> */}
      <section className={s.section_5}>
        <div className={s.wrapper}>
          <SubscribeForm whiteText={true} />
        </div>
      </section>
    </>
  );
};
