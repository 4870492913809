export const faqBlocks = [
  {
    title: 'What should I bring?',
    subtitle:
      'A valid passport is required to travel down to Valle de Guadalupe. If you have global entry, be sure to bring it and that is has been activated!',
  },
  {
    title: 'How does entering into Mexico work?',
    subtitle:
      'The border is about a 20 minute drive from downtown San Diego. When crossing into Mexico, everyone stays in the car and your passport does not need to be shown. A quick inspection is done by border agents, and then get ready to crack open your ice cold Tecates!',
  },
  {
    title: 'How does entering back into the US work?',
    subtitle: `We have a vehicle pass for Global Entry/Sentri, so anyone with Global Entry is welcome to stay in the van. Otherwise, you'll use the pedestrian lane, which normally takes 45 minutes or less. We do this to avoid the 2+ hour wait time in the general car lane.`,
  },
  {
    title: 'Where do we depart from?',
    subtitle:
      'For trips that have been upgraded to private, we will pick you up from your desired pickup location within San Diego County. Public trips depart from Balboa Park (free parking).',
  },
];

export const faqMultiBlocks = [
  {
    title: 'What should I bring?',
    subtitle:
      'A valid passport is required to travel to Mexico. If you have global entry, be sure to bring it and that is has been activated!',
  },
  {
    title: 'How does entering into Mexico work?',
    subtitle:
      'The border is about a 20 minute drive from downtown San Diego. When crossing into Mexico, everyone stays in the car and your passport does not need to be shown. A quick inspection is done by border agents, and then get ready to crack open your ice cold beverages!',
  },
  {
    title: 'How does entering back into the US work?',
    subtitle: `We have a vehicle pass for Global Entry/Sentri, so anyone with Global Entry is welcome to stay in the van. Otherwise,  Otherwise, you'll use the pedestrian lane, which normally takes 45 minutes or less. We do this to avoid the 2+ hour wait time in the general car lane.`,
  },
  {
    title: 'Can you accommodate specific winery/restaurant requests?',
    subtitle:
      'Absolutely! As long as we have enough notice, this is something that we can easily accommodate.',
  },
];

export const faqWeekendBlocks = [
  {
    title: 'What should I bring?',
    subtitle:
      'A valid passport is required to travel to Mexico. If you have global entry, be sure to bring it and that is has been activated!',
  },
  {
    title: 'How does entering into Mexico work?',
    subtitle:
      'The border is about a 20 minute drive from downtown San Diego. When crossing into Mexico, everyone stays in the car and your passport does not need to be shown. A quick inspection is done by border agents, and then get ready to crack open your ice cold beverages!',
  },
  {
    title: 'How does entering back into the US work?',
    subtitle: `We have a vehicle pass for Global Entry/Sentri, so anyone with Global Entry is welcome to stay in the van. Otherwise,  Otherwise, you'll use the pedestrian lane, which normally takes 45 minutes or less. We do this to avoid the 2+ hour wait time in the general car lane.`,
  },
  {
    title: 'How long is the trip?',
    subtitle: 'We will depart around 9:00am, and arrive back in the US around 3:00pm the next day.',
  },
];

export const faqPublicBlocks = [
  {
    title: 'What should I bring?',
    subtitle:
      'A valid passport is required to travel to Mexico. If you have global entry, be sure to bring it and that is has been activated!',
  },
  {
    title: 'How does entering into Mexico work?',
    subtitle:
      'The border is about a 20 minute drive from downtown San Diego. When crossing into Mexico, everyone stays in the car and your passport does not need to be shown. A quick inspection is done by border agents, and then get ready to crack open your ice cold beverages!',
  },
  {
    title: 'How does entering back into the US work?',
    subtitle: `We have a vehicle pass for Global Entry/Sentri, so anyone with Global Entry is welcome to stay in the van. Otherwise,  Otherwise, you'll use the pedestrian lane, which normally takes 45 minutes or less. We do this to avoid the 2+ hour wait time in the general car lane.`,
  },
  {
    title: 'Where do we depart from?',
    subtitle:
      'We have three different pickup locations to choose from (Encinitas, Mission Valley, Chula Vista).',
  },
];
